// fonts
@font-face {
  font-family: 'NotoSerif-VariableFont_wdth,wght';
  src: url('../public/fonts/NotoSerif-VariableFont_wdth,wght.ttf');
};

$theme-colors: (
  "danger": red,
  "primary": #E45E25,
  "secondary": #364469,
);

// plugins
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.css";

body {
  color: #EDF2F5;
  background-color: #4D4E4E;
  margin: 0;
  font-family:'NotoSerif-VariableFont_wdth,wght', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none; /* no underline */
}

.logo-bar {
  padding-top: 5px;
  display: flex;
  height: 50px;
  flex-direction: row;
  justify-content: center;
}

.back-button {
  position: absolute;
  top: 20px;
  left: 200px;
}

.relative-back-button {
  position: relative;
  top: -95px;
  left: 200px;
}

@import "./components/ReportDetails/ReportDetails";
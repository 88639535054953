.report-details {
  display: flex;
  flex: 1 0;
  flex-direction: row;
  align-items: stretch;

  .content {
    display: flex;
    flex-direction: column;
    flex: 1 0;
    padding: 4px;

    label {
      display: flex;
    }

    .sentiment-details {
      display: flex;
      flex-direction: column;
      flex: 0 1;
      border-top: 1px solid #666666;
      border-bottom: 1px solid #666666;
      margin-top: 4px;
      padding-top: 4px;

      .sentiment-svg {
        margin-top: 4px;
      }
    }
  }

  .legend {
    display: flex;
    flex: 0 1;
    width: 300px;
  }
}
td {
    border: 1px solid rgb(44, 42, 42);
    padding: 5px;
}

th {
    border: 1px solid rgb(44, 42, 42);
    padding: 5px;
}

thead {
    background: #364469;
    margin: 0;
    position: sticky;
    top: 0;
}

.nav-tabs {
    --bs-nav-tabs-border-width: 1px;
    --bs-nav-tabs-border-color: darkgray;
    --bs-nav-tabs-link-active-border-color: darkgray;

    .nav-link {
        border-color: rgb(44, 42, 42);;
    }
}

.nav-item {
    background-color: #364469;
    --bs-nav-link-color: darkgray;
    --bs-nav-tabs-border-color: darkgray;
}
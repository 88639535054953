* {
    -webkit-box-sizing: border-box;
    /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;
    /* Firefox, other Gecko */
    box-sizing: border-box;
}

html,
body {
    margin: 0px;
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    max-height: 100%;
    -webkit-box-sizing: border-box;
    /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;
    /* Firefox, other Gecko */
    box-sizing: border-box;
    /* overflow-y: hidden; */
    overflow-x: hidden;
}

#main {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    width: 100%;
}

#header {
    display: flex;
    flex: 0 1;
    flex-direction: row;
    align-items: flex-end;
    justify-content: baseline;
    height: 46px;
    background: rgb(20, 20, 20);
    padding-left: 20px;
}

.header-label {
    display: flex;
    font-size: 36px;
    margin-right: 20px;
}

.header-report-date {
    display: flex;
    font-size: 24px;
    color: #AAA;
}

#display {
    display: flex;
    flex: 1 0;
    flex-direction: column;
    align-items: stretch;
}

.tupelo-display-row {
    display: flex;
    flex: 1 0;
    border-bottom: 1px solid #777;
    padding: 10px;
}

.tupelo-container {
    display: flex;
    flex-direction: column;
    flex: 1 0;
    padding: 10px;
    align-items: stretch;
}

.tupelo-container-header {
    flex: 0 1; 
    background: #364469;
    padding: 5px;
}

.tupelo-timeline-container {
    display: flex;
    flex: 0 1;
    flex-direction: column;
    align-items: stretch;
}

#tupelo-map-container {
    display: flex;
    flex: 0 1;
}

#tupelo-heat-map {
    height: 500px;
    width: 1000px;
}

#tupelo-look-container {
    display: flex;
    flex: 1 0;
}

#tupelo-look-chart {
    display: flex;
    flex: 1 0;
}

#tupelo-table {
    display: flex;
    flex: 1 0;
    flex-direction: column;
    align-items: stretch;
    max-height: 30vh;
}

#tupelo-table-header {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: stretch;
    background: black;
}

#tupelo-table-body-wrapper {
    display: flex;
    flex: 1 0;
    flex-direction: column;
    align-items: stretch;
    overflow-y: auto;
}

#tupelo-table-body {
    display: flex;
    flex: 1 0;
    flex-direction: column;
    align-items: stretch;
}

.tupelo-table-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: stretch;
    border-bottom: 1px solid rgb(220, 220, 220);
}

.tupelo-table-col {
    display: flex;
    text-align: left;
    padding: 10px 0px;
    font-size: 16px;
}

#footer {
    position: relative;
    float: left;
    width: 100%;
    height: 46px;
    background: rgb(20, 20, 20);
    text-align: center;
    /* padding: ; */
}
